import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Modal from "../../../common/Modal";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";
import ConfirmModal from "../../../common/ConfirmModal";
import {API} from "aws-amplify";
import AddEditDepartment from "./AddEditDepartment";
import {isDepartmentFeatureDisabled} from "../../../shared/access";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Department Name', minWidth: 170 }
];

const DepartmentsIndex = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [departments, setDepartments] = React.useState([]);
    const [isFeatureDisabled, setIsFeatureDisabled] = React.useState(null);


    const loadDepartments = () => {
        API.get("Core", "/api/v1/department")
            .then(response => {
                    setDepartments(response);
                },
                error => {
                    toast("Could not get departments: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    React.useEffect(() => {
        const checkFeatureStatus = async () => {
            const featureDisabled = await isDepartmentFeatureDisabled();
            setIsFeatureDisabled(!featureDisabled);
            if (featureDisabled) {
                toast("Department feature is disabled", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            } else {
                loadDepartments();
            }
        };
        checkFeatureStatus();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDepartmentAdd = (department) => {
        setOpen(false);
        loadDepartments();
    };

    const handleEditClose = (department) => {
        editOpen[department.id] = false;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditOpen = (department) => {
        editOpen[department.id] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (department) => {
        editOpen[department.id] = false;
        setEditOpen(Object.assign({}, editOpen));
        loadDepartments();
    }

    const deleteDepartment = (departmentId) => {
        API.del('Core', '/api/v1/department/' + departmentId)
            .then(response => {
                    toast("Deleted Department!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                    loadDepartments();
                },
                error => {
                    toast("Could not Delete Department" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        isDepartmentFeatureDisabled() ? null :
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Modal
                        button={(<Button variant="contained" color="primary" className={classes.button}>
                            Add Department
                        </Button>)}
                        content={(<AddEditDepartment
                            onCancel={handleClose}
                            onSuccess={handleDepartmentAdd}
                        />)}
                        title="Add Department"
                        size="small"
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        open={open}
                    />
                </Grid>


                <Grid xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {departments.map(row => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map(column => {
                                            let value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell key={'edit'}>
                                            <Modal
                                                button={(<Button variant="contained" color="primary"
                                                                 className={classes.button}>
                                                    Edit
                                                </Button>)}
                                                content={(<AddEditDepartment
                                                    existingDepartment={row}
                                                    onCancel={handleEditClose.bind(this, row)}
                                                    onSuccess={handleEditSuccess.bind(this, row)}
                                                />)}
                                                title="Edit Department"
                                                size="small"
                                                handleClose={handleEditClose.bind(this, row)}
                                                handleOpen={handleEditOpen.bind(this, row)}
                                                open={editOpen[row.id]}
                                            />
                                            <ConfirmModal
                                                title={"Delete Department"}
                                                text={(<span>Are you sure you want to delete <b>{row.name}</b>?</span>)}
                                                onConfirm={deleteDepartment.bind(this, row.id)}
                                                confirmTitle={"Delete"}
                                            >
                                                <Button variant="contained" color="primary" className={classes.button}>
                                                    Delete
                                                </Button>
                                            </ConfirmModal>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
    )
}

export default DepartmentsIndex;
